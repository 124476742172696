<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCol xl="12" style="text-align: center;" v-if="isHLoading">
        <CSpinner
          color="info"
          style="width:1rem;height:1rem;"
        />
      </CCol>
      <CCol xl="6" v-if="!isHLoading">
        <CRow>
          <CCol tag="label" xl="2" class="col-form-label font-weight-bold">
            AWOS:
          </CCol>
          <CCol xl="10" class="">
            <multiselect
              @input="changeAwos"
              :value="value_awos"
              :options="awos"
              track-by="value"
              label="label"
              :multiple="true"
              placeholder="Choose Awos"
              :close-on-select="false"
              :clear-on-select="false">
            </multiselect>
          </CCol>
        </CRow>
      </CCol>
      <CCol xl="6" v-if="!isHLoading">
        <CRow>
          <CCol tag="label" xl="2" class="col-form-label font-weight-bold">
            RADAR:
          </CCol>
          <CCol xl="10" class="">
            <multiselect
              @input="changeRadar"
              :value="value_radar"
              :options="radar"
              track-by="value"
              label="label"
              :multiple="true"
              placeholder="Choose Radar"
              :close-on-select="false"
              :clear-on-select="false">
            </multiselect>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol xl="12" style="text-align: center;" v-if="isRefresh">
        <CSpinner
          color="info"
          style="width:3rem;height:3rem;"
        />
      </CCol>
      <CCol xl="6" class="padding-0">
      <CCard class="col-xl-12 padding-0 mb-3" v-for="(awoss, idx) in awosdata" :key="'a'+idx">
        <CCardBody class="padding-5">
          <CRow class="pl-3 margin-0">
            <CCol xl="12" class="padding-0"><span class="card-title font-weight-bold" style="font-size: 1.3rem;">AWOS {{awoss.airport}}</span></CCol>
            <CCol v-if="awoss.runway.length==0" xl="12" class="padding-0 text-center mt-3 mb-3"><span>Not Found</span></CCol>
            <CCol xl="12" v-for="(runwayawos, idy) in awoss.runways" :key="'a'+idx+'-'+idy" class="mb-2 padding-0">
              <CRow class="margin-0">
                <CCol xl="7" class="pt-3 padding-0">
                  <span>Runway {{awoss.runway[idy]}}</span>
                  <CRow class="margin-0 mt-3">
                    <CCol xl="6" class="padding-0">
                      <CRow class="margin-0">
                        <CCol class="padding-0">
                          <v-icon name="wind" class="font-dashblue"/>
                          <span class="title font-weight-bold ml-1">Wind Speed</span>
                        </CCol>
                      </CRow>
                      <span class="ml-4">{{valnum(awoss.runways[idy].wind_speed)}} {{valstr(awoss.runways[idy].wind_speed_unit)}}</span>
                    </CCol>
                    <CCol xl="6" class="padding-0">
                      <CRow class="margin-0">
                        <CCol class="padding-0">
                          <v-icon name="wind" class="font-dashblue"/>
                          <span class="title font-weight-bold ml-1">Wind Direction</span>
                        </CCol>
                      </CRow>
                      <span class="ml-4">{{valnum(awoss.runways[idy].wind_direction)}}{{valstr(awoss.runways[idy].wind_direction_unit)}}</span>
                    </CCol>
                  </CRow>
                  <CRow class="mt-4 margin-0">
                    <CCol xl="6" class="padding-0">
                      <CRow class="margin-0">
                        <CCol class="padding-0">
                          <v-icon name="road" class="font-dashblue"/>
                          <span class="title font-weight-bold ml-1">RVR</span>
                        </CCol>
                      </CRow>
                      <span class="ml-4">{{valnum(awoss.runways[idy].rvr)}} {{valstr(awoss.runways[idy].rvr_unit)}}</span>
                    </CCol>
                    <CCol xl="6" class="padding-0">
                      <CRow class="margin-0">
                        <CCol class="padding-0">
                          <v-icon name="eye" class="font-dashblue"/>
                          <span class="title font-weight-bold ml-1">Visibility</span>
                        </CCol>
                      </CRow>
                      <span class="ml-4">{{valnum(awoss.runways[idy].visibility)}} {{valstr(awoss.runways[idy].visibility_unit)}}</span>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol xl="5" class="padding-0">
                  <MetarAwosRadar :arrow="awoss.knotdegree.arrow_rotate[idy]" :knot="awoss.knotdegree.knot_val[idy]" :flight="awoss.knotdegree.flight_rotate[idy]" style="width:200px; height:200px;"/>
                </CCol>
              </CRow>
              <CRow class="mt-2 margin-0">
                <CCol xl="2" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Temp</span>
                    </CCol>
                  </CRow>
                  <span class="ml-1">{{valnum(awoss.runways[idy].temperature)}}{{valstr(awoss.runways[idy].temperature_unit)}}</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Humidity</span>
                    </CCol>
                  </CRow>
                  <span class="ml-1">{{valnum(awoss.runways[idy].relative_humidity)}}{{valstr(awoss.runways[idy].relative_humidity_unit)}}</span>
                </CCol>
                <CCol xl="2" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Dew Point</span>
                    </CCol>
                  </CRow>
                  <span class="ml-1">{{valnum(awoss.runways[idy].dew_point)}}{{valstr(awoss.runways[idy].dew_point_unit)}}</span>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Lightning</span>
                    </CCol>
                  </CRow>
                  <CRow class="margin-0 ml-1"><span>{{valstr(awoss.runways[idy].lightning)}}</span></CRow>
                </CCol>
                <CCol xl="3" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Sky Condition</span>
                    </CCol>
                  </CRow>
                  <CRow class="margin-0 ml-1"><span>{{valstr(awoss.runways[idy].sky)}}</span></CRow>
                </CCol>
              </CRow>
              <CRow class="mt-2 margin-0">
                <CCol xl="4" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">Present Weather</span>
                    </CCol>
                  </CRow>
                  <CRow class="margin-0 ml-1"><span>{{valstr(awoss.runways[idy].present_weather)}}</span></CRow>
                </CCol>
                <CCol xl="8" class="padding-0">
                  <CRow class="margin-0">
                    <CCol class="padding-0">
                      <span class="title font-weight-bold ml-1">METAR</span>
                    </CCol>
                  </CRow>
                  <CRow class="margin-0 ml-1"><span>{{valstr(awoss.runways[idy].metar)}}</span></CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      </CCol>
      <CCol xl="6" class="padding-0">
      <CCard class="col-xl-12 padding-0 mb-3" v-for="(radars, idx) in radardata" :key="'r'+idx">
        <CCardBody class="padding-5">
          <CRow class="margin-0">
            <CCol xl="12" class="padding-0">
              <span class="pl-3 card-title font-weight-bold" style="font-size: 1.3rem;">RADAR {{radars.airport}}</span>
              <CRow class="margin-0 mt-2" v-if="radars.images.length>0">
                <div v-if="!changebtntriger" style="width: 100%; height: 30vw; position: relative;">
                  <panZoom :options="{minZoom: 1, maxZoom: 5}" @panstart="onPanStart(idx)" @zoom="onZoom(idx)" class="text-center" style="overflow: hidden; height: 30vw;">
                    <carousel :per-page="1" :loop="true" easing="ease-in-out"
                      :autoplay="true" :autoplayTimeout="delay" :paginationEnabled="false" 
                      :mouseDrag="false" :speed="0" :value="0" :centerMode="true" :class="{'nonetrans': ispanzoom[idx]}">
                      <slide v-for="(map, idy) in radars.images.slice().reverse()" :key="'r'+idx+'-'+idy">
                          <img :src="map.image_radar" alt="" class="img-fluid" style="width:100%;">
                      </slide>
                    </carousel>
                  </panZoom>
                  <div class="btn-sidedash-radar" @click="showlegend(idx)" :style="isLegend[idx]?'right: 30%;':'right: 0;'">
                    <v-icon :name="isLegend[idx]?'caret-right':'caret-left'" style="height: 100%; vertical-align: middle;"/>
                  </div>
                  <div v-if="isLegend[idx]" class="sidedash-radar" style="width: 30%;">
                    <carousel :per-page="1" :loop="true" easing="ease-in-out"
                      :autoplay="true" :autoplayTimeout="delay" :paginationEnabled="false" 
                      :mouseDrag="false" :speed="0" :value="0" :centerMode="true" class="col-xl-12">
                      <slide v-for="(maplg, idl) in radars.images.slice().reverse()" :key="'rl'+idx+'-'+idl">
                        <img :src="maplg.image_legend" alt="" style="width: 100%;">
                      </slide>
                    </carousel>
                  </div>
                </div>
              </CRow>
            </CCol>
            <CCol v-if="radars.images.length==0" xl="12" class="padding-0 text-center mt-3 mb-3"><span>Not Found</span></CCol>
          </CRow>
        </CCardBody>
      </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import { Carousel, Slide } from 'vue-carousel';
import MetarAwosRadar from '../components/MetarAwosRadar'

export default {
  name: 'Dashboard',
  components: { 
    Multiselect,
    Carousel,
    Slide,
    MetarAwosRadar
  },
  data () {
    return {
      isLoading: false, isHLoading: false, isRefresh: false,
      form: {
        filter_awos: [],
        filter_radar: []
      },
      errors: {
        filter_awos: '',
        filter_radar: ''
      },
      awos: [],
      value_awos: [],
      awosdata: [],
      radar: [],
      value_radar: [],
      radardata: [],
      isLegend: [],
      ispanzoom: [],
      list_alertdsb: [],
      check_listalertdsb: [],
      delay: 3000,
      changebtntriger: false
    }
  },
  watch: {
    value_awos: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { this.isRefresh = true }
        }
    },
    value_radar: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { this.isRefresh = true }
        }
    },
    changebtntriger: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { this.changebtntriger = false }
        }
    },
  },
  created() {
    this.onLoad()
    this.showUserconfig()
    // this.getAirport()
  },
  beforeDestroy() {
    clearInterval(this.intervalAWOSRADAR)
  },
  methods: {
    ...mapActions('airport', ['indexAPT']),
    ...mapActions('profile', ['showPFL']),
    ...mapActions('dashboard', ['indexDSH']),
    ...mapActions('configs', ['indexUCFG']),
    onLoad() {
      this.intervalAWOSRADAR = setInterval(this.postAwosRadar, 10000)
    },
    async getAirport(){
      this.isHLoading = true
      await this.showPFL().then((response) => {
          response.airport_rights.forEach((item, index) => {
              this.awos[index] = { value: item.id, label: item.name+" ("+item.code+")" }
              this.radar[index] = { value: item.id, label: item.name+" ("+item.code+")" }
              this.isLegend[index] = true
              this.ispanzoom[index] = true
          })
          this.isHLoading = false
          if(response.airport_rights.length>0) {
            this.value_awos = this.awos[0]
            this.value_radar = this.radar[0]
            this.form.filter_awos[0] = this.awos[0].value
            this.form.filter_radar[0] = this.radar[0].value
            this.postAwosRadar()
          }
      }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error })
          this.isHLoading = false
      })
    },
    async postAwosRadar(){
      // if(this.form.filter_awos.length>0||this.form.filter_radar.length>0) {
        this.isLoading = true
        if(this.isRefresh){
          this.awosdata = []
          this.radardata = []
        }
        await this.indexDSH(this.form).then((response) => {
          this.awosdata = response.awos
          this.radardata = response.radar
          this.list_alertdsb = []
          var store_listalertdsb = this.$store.state.listalertdsb ? this.$store.state.listalertdsb : []

          response.awos.forEach((item, i) => {
            this.awosdata[i] = {}
            var objRunways = Object.keys(item.runways).sort((a , b) => b - a).map((key) => {
              return item.runways[key]
            })
            var arrRunways = Object.keys(item.runways).sort((a , b) => b - a).map((key) => {
              return key.length<2 ? '0'+key:key
            })
            var awosRunways = this.getRotate(objRunways, arrRunways)
            
            this.awosdata[i] = { 
              airport: item.airport,
              runways: objRunways,
              runway: arrRunways,
              knotdegree: awosRunways,
            }

            item.alerts.forEach((itl, il) => {
              this.list_alertdsb.push(itl.message)
            })
          })

          /* Start Alert */
          this.check_listalertdsb = this.list_alertdsb.filter(function(item) {
            if(!store_listalertdsb.includes(item)) return true
          })
          if(this.check_listalertdsb.length>0) {
            this.check_listalertdsb.forEach((item, i) => {
              this.flashMessage.warning({ title: 'Information', message: item, time: 7000 })
            })
            localStorage.setItem('listalertdsb', JSON.stringify(this.list_alertdsb))
            this.$store.commit('SET_LISTALERTDSB', this.list_alertdsb, { root: true })
          }
          /* End Alert */

          this.isRefresh = false
          this.isLoading = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.getError(error.data)
          this.isLoading = false
        })
      // }
    },
    showUserconfig() {
      this.isHLoading = true
      this.indexUCFG().then((response) => {
        response.available_airports.forEach((item, index) => {
          this.awos[index] = { value: item.id, label: item.name+" ("+item.code+")" }
          this.radar[index] = { value: item.id, label: item.name+" ("+item.code+")" }
          this.isLegend[index] = true
          this.ispanzoom[index] = true
        })
        /* Awos Dashboard */
        this.form.filter_awos = response.awos_dashboard.map(function (obj) {
          return obj.id
        })
        this.value_awos = response.awos_dashboard.map(function (obj) {
          return { value: obj.id, label: obj.name }
        })
        /* Radar Dashboard */
        this.form.filter_radar = response.radar_dashboard.map(function (obj) {
          return obj.id
        })
        this.value_radar = response.radar_dashboard.map(function (obj) {
          return { value: obj.id, label: obj.name }
        })
        if(this.value_awos<1) {
          this.value_awos = this.awos[0]
          this.form.filter_awos[0] = this.awos[0].value
        }
        if(this.value_radar<1) {
          this.value_radar = this.radar[0]
          this.form.filter_radar[0] = this.radar[0].value
        }
        this.isHLoading = false
        this.postAwosRadar()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isHLoading = false
      })
    },
    changeAwos(val) {
      this.value_awos = val
      this.form.filter_awos = val.map(function (obj) {
        return obj.value
      })
      this.postAwosRadar()
    },
    changeRadar(val) {
      this.value_radar = val
      this.form.filter_radar = val.map(function (obj) {
        return obj.value
      })
      this.postAwosRadar()
    },
    getRotate(val, rw) {
      var arrow1 = val[0]?val[0].wind_direction:'0'
      var arrow2 = val[1]?val[1].wind_direction:'0'
      var arrow_rotate = [arrow1, arrow2]

      var knot1 = val[0]?val[0].wind_speed:'0'
      var knot2 = val[1]?val[1].wind_speed:'0'
      var knot_val = [knot1, knot2]

      var flight_val = 0;
      if(rw.length>0) {
        var rw1 = rw[0]?parseInt(rw[0]):0
        var rw2 = rw[1]?parseInt(rw[1]):0
        flight_val = rw1>rw2 ? rw1:rw2
      }
      var flight1 = flight_val*10
      var flight2 = flight_val*10
      var flight_rotate = [flight1, flight2]

      return {arrow_rotate: arrow_rotate, knot_val: knot_val, flight_rotate: flight_rotate}
    },
    showlegend(idx) {
      this.isLegend[idx] = !this.isLegend[idx]
      this.changebtntriger = true
    },
    onPanStart(idx) {
      var oldval = this.ispanzoom[idx]; this.ispanzoom[idx] = false
      if(oldval) this.changebtntriger = true
    },
    onZoom(idx) {
      var oldval = this.ispanzoom[idx]; this.ispanzoom[idx] = false
      if(oldval) this.changebtntriger = true
    },
    valnum(v){
      if(!v) return 0
      else return v
    },
    valstr(v){
      if(!v) return '-'
      else return v
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.filter_awos = data.errors.filter_awos ? data.errors.filter_awos[0] : '' 
        this.errors.filter_radar = data.errors.filter_radar ? data.errors.filter_radar[0] : '' 
      } else { this.errors = { filter_awos: '', filter_radar: '' }} }
    }
  }
}
</script>