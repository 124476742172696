<template>
    <div>
        <img src="storage/metar/awos/awosdegree.svg" style="width:200px; height:200px; position: absolute;"/>
        <img src="storage/metar/awos/runway.svg" style="width:200px; height:200px; position: absolute;" :style="flight_direct"/>
        <img src="storage/metar/awos/bluearrow.svg" style="width:200px; height:200px; position: absolute;" :style="arrow_direct"/>
        <img src="storage/metar/awos/bluebar.svg" style="width:200px; height:200px; position: absolute;" :style="bar_direct"/>
        <span style="width:200px; height:200px; position: absolute; top: 60px; text-align: center;" class="st0span st1">{{_arrow}}</span>
        <span style="width:200px; height:200px; position: absolute; top: 95px; text-align: center;" class="st0span st1">{{_knot}}<small style="font-size: 15px;">kt</small></span>
    </div>
</template>

<script>
    export default {
        props: {
            arrow: { type: Number, default: () => 0 },
            knot: { type: Number, default: () => 0 },
            flight: { type: Number, default: () => 0 },
        },
        data() {
            return {
                arrow_direct: "transform: rotate(0deg);",
                bar_direct: "transform: rotate(0deg);",
                flight_direct: "transform: rotate(0deg);",
                _arrow: "0°",
                _knot: "0"
            }
        },
        created() {
            this.changeArrow()
        },
        mounted() {
            this.changeArrow()
        },
        watch: {
            arrow: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeArrow()
                    }
                }
            },
            knot: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeArrow()
                    }
                }
            },
            flight: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeArrow()
                    }
                }
            },
        },
        methods: {
            changeArrow() {
                var v = this.arrow
                var w = this.knot
                var y = this.flight
                var x = Math.round((this.arrow/10))*10
                this.arrow_direct = ""
                this.bar_direct = ""
                this.flight_direct = ""
                this._arrow = ""
                this._knot = ""

                this.arrow_direct = "transform: rotate("+v+"deg);"
                this.bar_direct = "transform: rotate("+x+"deg);"
                this.flight_direct = "transform: rotate("+y+"deg);"
                this._arrow = v+"°"
                this._knot = w+""
            }
        },
    }
</script>
<style type="text/css">
    .st0span{
        -moz-text-fill-color: #3c4b64;
        -webkit-text-fill-color: #3c4b64;
        -moz-text-stroke-color: #3c4b64;
        -webkit-text-stroke-color: #3c4b64;
        -moz-text-stroke-width: 1px;  
        -webkit-text-stroke-width: 1px;
        font-size:28px;
    }
    .st1{font-family:'Roboto';}
    .st3{stroke:#070707;stroke-width:0.5;stroke-miterlimit:10;}
    .st4{font-size:14px;}
</style>